
class Trigger {
	constructor(trigger) {
		this.localStorageId = `conditionalTrigger-${trigger.id}`
		this.conditions = trigger.conditions;
		this.eventName = trigger.eventName;
		this.eventConfiguration = trigger.eventConfiguration;
		this.triggerBrandCode = trigger.brandCode;
		this.numberOfOccurences = trigger.numberOfOccurences;
		this.initialize();
	}

	get siteConfig() {
		return window.FCA_SITES_CONFIG;
	}

	get brandCode() {
		return this.siteConfig.brandCode;
	}
	get pageCode() {
		return this.siteConfig.pageCode;
	}

	get getModelYearId() {
		return this.siteConfig.modelYearIds;
	}

	isModelYearPresent(condition) {
		return condition.modelYearIds !== undefined ?  condition.modelYearIds.some(r => this.getModelYearId.includes(r)) : true;
	}
	isConditionPresentOnPage(condition) {
		return this.pageCode === condition.pageCode &&
			this.triggerBrandCode === this.brandCode &&
			this.isModelYearPresent(condition);
	}

	get localStorageValue() {
		const value = localStorage.getItem(this.localStorageId);
		return value == null ? {} : JSON.parse(value);
	}

	set localStorageValue(value) {
		localStorage.setItem(this.localStorageId, JSON.stringify(value));
	}
	handlingTriggerEvent(conditionName) {
		this.updateLocalStorageValue(conditionName);
		this.isAllConditionsDone();
	}

	isPreviousActionDone(localStorageValue, name) {
		const keysActions =  Object.keys(localStorageValue.actions);
		const hasMultiplesAction = keysActions.length > 1;

		if (!hasMultiplesAction ) {
			return true;
		}

		const indexCurrentAction = keysActions.indexOf(name);

		return indexCurrentAction != 0 ? localStorageValue.actions[keysActions[indexCurrentAction - 1]] : true;
	}
	updateLocalStorageValue(conditionName) {
		const localStorageValue = this.localStorageValue;

		if (localStorageValue.actions) {
			localStorageValue.actions[conditionName] = true;
		}

		this.localStorageValue = localStorageValue;
	}

	dispatchEvent() {
		Logger.log("Open the modal", this.eventName);
		PubSubManager.publish(this.eventName, { detail: this.eventConfiguration ? { eventConfiguration: this.eventConfiguration } : null});
	}

	isAllConditionsDone() {
		const localStorageValue = this.localStorageValue;
		const isDone = localStorageValue.actions && !Object.values(localStorageValue.actions).includes(false);

		if (isDone && (this.numberOfOccurences === null || localStorageValue.numberOfOccurencesSeen < this.numberOfOccurences)) {
			localStorageValue.numberOfOccurencesSeen = localStorageValue.numberOfOccurencesSeen + 1;
			this.localStorageValue = localStorageValue;
			this.dispatchEvent();
		}
	}

	bindClickEventListener(condition) {
		const triggerDOMElement = document.querySelector(condition.selector);

		if (triggerDOMElement) {
			triggerDOMElement.dataset.triggerName = condition.name;

			triggerDOMElement.addEventListener(condition.type, event => {
				if (event.type == "click" && event.target.href) {
					event.preventDefault();
					event.stopPropagation();
					this.handlingTriggerEvent(event.currentTarget.dataset.triggerName);
					window.location.href = event.target.href;
				} else {
					this.handlingTriggerEvent(event.currentTarget.dataset.triggerName);
				}
			});
		}
	}

	convertSecondsToMilliseconds(seconds) {
		return seconds * 1000;
	}
	bindPageLoadEventListener(condition) {
		setTimeout(() => {
			this.handlingTriggerEvent(condition.name);
		}, this.convertSecondsToMilliseconds(condition.delay));
	}

	bindInactivityEventListener(condition) {
		let inactivityTimeout;

		const resetTimer = () => {
			if (inactivityTimeout) {
				clearTimeout(inactivityTimeout);
			}
			inactivityTimeout = setTimeout(() => {
				this.handlingTriggerEvent(condition.name);
				document.removeEventListener('click', resetTimer);
			}, this.convertSecondsToMilliseconds(condition.delay));
		};

		document.addEventListener('click', resetTimer);

		// Start the timer initially
		resetTimer();
	}

	addEventListener() {
		this.conditions.forEach(condition => {
			//Logger.log('Condition', condition, ' --- isConditionPresentOnPage >', this.isConditionPresentOnPage(condition), ' --- isPreviousActionDone >', this.isPreviousActionDone(this.localStorageValue, condition.name), '--- this.localStorageValue >', this.localStorageValue, '--- condition.name', condition.name);
			if (this.isConditionPresentOnPage(condition) &&
				this.isPreviousActionDone(this.localStorageValue, condition.name)) {
				switch(condition.type) {
					case "click":
						this.bindClickEventListener(condition);
						break;

					case "page-load":
						this.bindPageLoadEventListener(condition);
						break;

					case "inactivity":
						this.bindInactivityEventListener(condition);
						break;
				}
			}
		});
	}

	isEmptyObj(obj) {
		return Object.keys(obj).length === 0;
	}
	/**
	 * Method that create the default localStorage object for the trigger
	 * if it isn't created yet
	 */
	addDefaultLocalStorage() {
		if (this.isEmptyObj(this.localStorageValue)) {
			const obj = {};
			obj.actions = {};

			this.conditions.forEach(condition => {
				obj.actions[condition.name] = false;
			});

			obj.eventName = this.eventName;
			obj.numberOfOccurencesSeen = 0;
			this.localStorageValue = obj;
		}
	}
	initialize() {
		this.addDefaultLocalStorage();
		this.addEventListener();
	}
}

/**
 * Class used to contains all logic to validate if we create a trigger or not
 */
class TriggerCreationValidator {
	validate(trigger) {
		const currentDate = new Date().setHours(0,0,0,0);
		const startDate = TriggerCreationValidator.createDate(trigger.startDate);
		const endDate = TriggerCreationValidator.createDate(trigger.endDate);

		return trigger.enabled && currentDate >= startDate && currentDate <= endDate;
	}

	static createDate(date) {
		return new Date(date + "T00:00:00");
	}

	static getInstance() {
		if (!this.instance) {
			this.instance = new TriggerCreationValidator();
		}
		return this.instance;
	}
}


class ConditionalTrigger {
	constructor(triggerCreationValidator, ) {
		this.triggerCreationValidator = triggerCreationValidator;
		this.initialization();
	}

	createTriggerListener() {
		this.removeDOMContentLoadedListener();
		const configuration = this.getConfiguration();
		if (configuration != null) {
			configuration.forEach((trigger) => {
				if (this.triggerCreationValidator.validate(trigger)) {
					new Trigger(trigger);
				} else {
					Logger.warn("The conditional trigger is expired, please remove it in the content");
				}
			});
		}
	}

	getConfiguration() {
		return window.FCA_CONDITIONAL_TRIGGER || null;
	}

	addDOMContentLoadedListener() {
		document.addEventListener("DOMContentLoaded", this.onBoundCreaterListenerEventHandler);
	}

	removeDOMContentLoadedListener() {
		document.removeEventListener('DOMContentLoaded', this.onBoundCreaterListenerEventHandler)
	}

	initialization() {
		this.onBoundCreaterListenerEventHandler = this.createTriggerListener.bind(this);
		this.addDOMContentLoadedListener();
	}
}


(function () {
	const triggerValidator = TriggerCreationValidator.getInstance();
	new ConditionalTrigger(triggerValidator);
}());
