const provinces = {
	en: [
		{ code: "AB", name: "Alberta" },
		{ code: "BC", name: "British Columbia" },
		{ code: "MB", name: "Manitoba" },
		{ code: "NB", name: "New Brunswick" },
		{ code: "NL", name: "Newfoundland and Labrador" },
		{ code: "NS", name: "Nova Scotia" },
		{ code: "ON", name: "Ontario" },
		{ code: "PE", name: "Prince Edward Island" },
		{ code: "QC", name: "Quebec" },
		{ code: "SK", name: "Saskatchewan" },
		{ code: "NU", name: "Nunavut" },
		{ code: "YT", name: "Yukon" },
		{ code: "NT", name: "Northwest Territories" }
	],
	fr: [
		{ code: "AB", name: "Alberta" },
		{ code: "BC", name: "Colombie-Britannique" },
		{ code: "MB", name: "Manitoba" },
		{ code: "NB", name: "Nouveau-Brunswick" },
		{ code: "NL", name: "Terre-Neuve-et-Labrador" },
		{ code: "NS", name: "Nouvelle-Écosse" },
		{ code: "ON", name: "Ontario" },
		{ code: "PE", name: "Île-du-Prince-Édouard" },
		{ code: "QC", name: "Québec" },
		{ code: "SK", name: "Saskatchewan" },
		{ code: "NU", name: "Nunavut" },
		{ code: "YT", name: "Yukon" },
		{ code: "NT", name: "Northwest Territories" }
	]
};

window.FCA_PROVINCES = (function() {
	const language = window.FCA_SITES_CONFIG && window.FCA_SITES_CONFIG.language ? window.FCA_SITES_CONFIG.language : 'en';
	return provinces[language] || provinces['en']; // Default to English if language is not supported
})();
